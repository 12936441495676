import React from 'react'
import Layout from "../components/App/Layout"
import SEO from '../components/App/SEO'
import NavbarDynamic from "../components/App/NavbarDynamic"
import PageBanner from '../components/Common/PageBanner'
import FooterMinimal from "../components/App/FooterMinimal"
import BlogCard from '../components/Blog/BlogCard'
import { pageHeader } from '../data/page-data/blogOverview';
import { graphql } from "gatsby"

const headerInfo = {
  title: 'Blog Posts | BryanLokey.com',
  description: 'I talk about engineering leadership, devops, and other tech stuff',
  keywords: 'engineering leadership, devops, blog',
  canonical: 'https://bryanlokey.com/blog/',
  ogImage: 'TBD',
};


const Blogs = ({
    data: {
      allMarkdownRemark: { edges },
    },
  }) => {
    const Posts = edges.filter(edge => !!edge.node.frontmatter.date) // filter your posts based on some criteria
    return (
      <div>
        <SEO {...headerInfo}/>
        <Layout>
         <NavbarDynamic />
         <PageBanner { ...pageHeader } />
         <BlogCard articles={Posts} />
         <FooterMinimal />
       </Layout>
      </div>
    )
  }
  export default Blogs
  export const pageQuery = graphql`
    query {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              thumbnail  {
                publicURL
              }
              image  {
                publicURL
              }
              description
              tags
            }
          }
        }
      }
    }`
